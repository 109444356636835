import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SSRProvider from 'react-bootstrap/SSRProvider'

import AboutSideMenu from '../../../../components/aboutSideMenu'
import BasicLearnMorePanel from '../../../../components/basicLearnMorePanel'
import ContactForm from '../../../../components/forms/contactForm'
import ContentQuoteCenter from "../../../../components/contentQuoteCenter.js"
import DefaultHeader from '../../../../components/default-header'
import Footer from '../../../../components/footer'
import GdprPanel from '../../../../components/gdpr'
import Locations from '../../../../components/locationsModule'
import Navigation from '../../../../components/navigation'
import Seo from '../../../../components/seo'
import NewsletterSignup from '../../../../components/newsletterSignUp.js';



const Page = ({ data, location }) => {

    const shortcodes = {
        ContentQuoteCenter,
        Row,
        Col,
      }

    return (      
        <>  
            <SSRProvider>
                <Navigation />
                <DefaultHeader type="xl" headline={data.mdx.frontmatter.headline} heroBackground={data.mdx.frontmatter.heroBackground} heroMobileBackground={data.mdx.frontmatter.heroMobileBackground} subHeadline={data.mdx.frontmatter.subheadline} />
                <div id="body">
                    <Container fluid="lg">
                        <Row>
                            <Col xl={9} md={8} className="pe-md-5 mb-md-5 mb-3">
                                <Row className="white-bg mb-5"> 
                                    <Col className="p-4">
                                        <MDXProvider components={shortcodes}>
                                            <MDXRenderer>{data.mdx.body}</MDXRenderer>
                                        </MDXProvider>   
                                    </Col>
                                </Row> 
                                <Locations />
                            </Col>
                            <Col xl={3} md={4} className="d-none d-md-block">
                                <AboutSideMenu></AboutSideMenu>
                                <BasicLearnMorePanel menuType="basic" />
                            </Col>
                            <Col xl={3} md={4} className="d-block d-md-none">
                                <BasicLearnMorePanel menuType="basic" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <ContactForm urlRef={location} submitCampaignID="7013i000000qH4WAAU" submitType="lead" />
                <Footer />
                <GdprPanel />
                <NewsletterSignup />
            </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage}
    />
)

export const query = graphql`
    query LocationsPageQuery {
        mdx(fileAbsolutePath: {regex: "/locations-and-centers.mdx/"})  {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }    
`

export default Page